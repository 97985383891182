import React, { useState } from 'react'
import AdminSidebar from '../../Sidebar/AdminSidebar';
import UserNavbar from '../../Navbars/UserNavbar'
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ToastContainer, toast } from 'react-toastify';
import { AdminApis } from '../../../apis/adminApi';
//@ts-ignore
import EllipsisText from "react-ellipsis-text";
import { useNavigate } from "react-router-dom";
import { AuthApis } from '../../../apis/authApis';
import { AxiosResponse } from 'axios';


function AddCourse() {

  const [schoolList, setSchoolList] = React.useState<any>([])
  React.useEffect(() => {
    AdminApis.getPartnerSchool('').then(
      (response: AxiosResponse<any>) => {
        if (response?.data?.success) {
          setSchoolList(response?.data?.data?.data)

          console?.log(response?.data?.data?.data)
        } else {
          // dispatch(login([]))
          // navigate('/login')
        }
      }
    );

  }, [])

  var fontSizeStyle = Quill.import('attributors/style/size');
  fontSizeStyle.whitelist = ['12px', '14px', '20px', '24px'];
  Quill.register(fontSizeStyle, true);



  var toolbarOptions = [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'size': fontSizeStyle.whitelist }],
    ['bold', 'italic', 'underline', 'link'],        // toggled buttons
    ['blockquote', 'code-block'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'align': [] }],
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    ['clean']                                         // remove formatting button
  ];
  const module = {
    toolbar: toolbarOptions
  }

  const [coverPhotoName, SetCoverPhotoName] = React.useState<any>("No selected file")
  const [courseOverview, setCourseOverview] = React.useState<any>("")
  const [aimOfTraining, setAimOfTraining] = React.useState<any>("")
  const [adminssionRequirement, setAdmissionrequirement] = React.useState<any>("")
  const [courseOutline, setCourseOutline] = React.useState<any>("")
  const [career, setCareer] = React.useState<any>("")

  const [errorMes, setErrorMes] = React.useState<any>("")
  const navigate = useNavigate();


  let [userData, setUserData] = React.useState({
    title: '', product_cost: "",
    tags: " ", school: "",
    category: "", ects: "",
    duration: "", website_url: "",
    twitter_link: "", instagram_link: "",
    facebook_link: "", video_url: "",
    key_1: "", value_1: "",
    key_2: "", value_2: "",
    key_3: "", value_3: "",
    key_4: "", value_4: "",
    key_5: "", value_5: "",
    key_6: "", value_6: "",
  });


  // const [careerDescription, setCareerDescription] = React.useState<any>("")

  const handleChange = (e: any) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  }

  const [coverPhoto, setCoverPhoto] = React.useState('No selected file');
  const coverPhotohandler = (e: any) => {
    // console?.log(e.target.files)
    const target = e.target
    let size = (target.files[0].size / 1048576.0)
    let targetName = target.name
    SetCoverPhotoName(e.target.files[0].name)
    // console?.log(size)
    if (target.files && target.files[0]) {
      if (size > 3) {
        if (targetName == 'coverPhoto') {
          setErrorMes("File too big!")
          toast.error("File is greater than 3MB!");
          target.value = ''
          console.log('error')
        }
      }
      if (size <= 3) {
        if (targetName == 'coverPhoto') {
          setErrorMes(" ")
          setCoverPhoto(e.target.files[0]);

        }
      }
    }
  };




  let [showScreen, setShowScreen] = React.useState<any>(1);
  const [createdCourseId, setCreatedCourseId] = React.useState<string | null>(null); // Add this state to store the course ID

  const handleSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();
      const blogData = new FormData();
      blogData.append('title', (userData?.title));
      blogData.append('overview', courseOverview);
      blogData.append('training_aim', aimOfTraining);
      blogData.append('admission_requirement', adminssionRequirement);
      blogData.append('course_outline', courseOutline);
      blogData.append('career', career);
      blogData.append('category', (userData?.category));
      blogData.append('product_cost', userData?.product_cost);
      blogData.append('tags', userData?.tags);
      blogData.append('school', userData?.school);
      blogData.append('ects', userData?.ects);
      blogData.append('duration', userData?.duration);
      blogData.append('website_url', (userData?.website_url));
      blogData.append('twitter_link', (userData?.twitter_link));
      blogData.append('instagram_link', (userData?.instagram_link));
      blogData.append('facebook_link', (userData?.facebook_link));
      blogData.append('cover_photo', coverPhoto);
      blogData.append('video_url', (userData?.video_url));
      blogData.append('key_1', (userData?.key_1));
      blogData.append('key_2', (userData?.key_2));
      blogData.append('key_3', (userData?.key_3));
      blogData.append('key_4', (userData?.key_4));
      blogData.append('key_5', (userData?.key_5));
      blogData.append('key_6', (userData?.key_6));
      blogData.append('value_1', (userData?.value_1));
      blogData.append('value_2', (userData?.value_2));
      blogData.append('value_3', (userData?.value_3));
      blogData.append('value_4', (userData?.value_4));
      blogData.append('value_5', (userData?.value_5));
      blogData.append('value_6', (userData?.value_6));

      AdminApis.createPartnerCourse(blogData).then(
        (response: any) => {
          if (response?.data) {
            console.log(response?.data?.data)
            setCreatedCourseId(response?.data?.data?.id); // Store the course ID here
            toast.success(response?.data?.message);
            // navigate('/course-list');
            setShowScreen(2)
          }
        }
      ).catch((err: any) => {
        if (err?.response?.status === 422) {
          console?.log(err)
          toast.error(err?.response?.data?.message);
        } else {
          toast.error(err?.response?.data?.message);
        }


      })
    },
    [userData, coverPhoto, courseOutline, courseOverview, aimOfTraining, career]
  );


  const [courseList, setCourseList] = React.useState<any>([])
  React.useEffect(() => {
    if (showScreen === 2 && createdCourseId) {
      AdminApis.getSingleCourse(createdCourseId).then((response: AxiosResponse<any>) => {
        if (response?.data?.success) {
          console.log('Fetched Course Data:', response?.data);
          // Handle the fetched course data here, e.g., update state
        } else {
          // Handle failure
        }
      }).catch((err: any) => {
        console.log('Error fetching course data:', err);
      });
    }
  }, [showScreen, createdCourseId]);
  
  // const createSchoolProgram = React.useCallback(
  //   (e: any) => {
  //     e.preventDefault();
  //     const blogData = new FormData();
  //     blogData.append('title', (userData?.title));
  //     blogData.append('overview', courseOverview);
  //     blogData.append('training_aim', aimOfTraining);
  //     blogData.append('admission_requirement', adminssionRequirement);
  //     blogData.append('course_outline', courseOutline);
  //     blogData.append('career', career);
  //     blogData.append('category', (userData?.category));
  //     blogData.append('product_cost', userData?.product_cost);
  //     blogData.append('tags', userData?.tags);
  //     blogData.append('school', userData?.school);
  //     blogData.append('ects', userData?.ects);
  //     blogData.append('duration', userData?.duration);
  //     blogData.append('website_url', (userData?.website_url));
  //     blogData.append('twitter_link', (userData?.twitter_link));
  //     blogData.append('instagram_link', (userData?.instagram_link));
  //     blogData.append('facebook_link', (userData?.facebook_link));
  //     blogData.append('cover_photo', coverPhoto);
  //     blogData.append('video_url', (userData?.video_url));
  //     blogData.append('key_1', (userData?.key_1));
  //     blogData.append('key_2', (userData?.key_2));
  //     blogData.append('key_3', (userData?.key_3));
  //     blogData.append('key_4', (userData?.key_4));
  //     blogData.append('key_5', (userData?.key_5));
  //     blogData.append('key_6', (userData?.key_6));
  //     blogData.append('value_1', (userData?.value_1));
  //     blogData.append('value_2', (userData?.value_2));
  //     blogData.append('value_3', (userData?.value_3));
  //     blogData.append('value_4', (userData?.value_4));
  //     blogData.append('value_5', (userData?.value_5));
  //     blogData.append('value_6', (userData?.value_6));

  //     AdminApis.createPartnerCourse(blogData).then(
  //       (response: any) => {
  //         if (response?.data) {
  //           console.log(response?.data?.data)
  //           toast.success(response?.data?.message);
  //           navigate('/course-list');
  //         }
  //       }
  //     ).catch((err: any) => {
  //       if (err?.response?.status === 422) {
  //         console?.log(err)
  //         toast.error(err?.response?.data?.message);
  //       } else {
  //         toast.error(err?.response?.data?.message);
  //       }


  //     })
  //   },
  //   [userData, coverPhoto, courseOutline, courseOverview, aimOfTraining, career]
  // );
  const [courses, setCourses] = useState([{ title: '', careerDescription: '' }]); // State to manage multiple courses
  // const [showScreen, setShowScreen] = useState(1); // Control screens


  const handleAddCourse = () => {
    setCourses([...courses, { title: '', careerDescription: '' }]); // Add a new empty course object
  };

  const handleRemoveCourse = (index: number) => {
    const newCourses = courses.filter((_, i) => i !== index);
    setCourses(newCourses);
  };

  const handleChange2 = (index: number, field: string, value: string) => {
    const newCourses:any = [...courses];
    newCourses[index][field] = value;
    setCourses(newCourses);
  };

  const handleSubmit2 = (e: React.FormEvent) => {
    e.preventDefault();

    // Create FormData object and append each course
    const formData = new FormData();
    courses.forEach((course, index) => {
      formData.append(`career[${index}][title]`, course.title);
      formData.append(`career[${index}][careerDescription]`, course.careerDescription);
    });

    // Send data to your endpoint
    AdminApis.createCourseCareerPath(formData) // Adjust the API method accordingly
      .then((response: any) => {
        // Handle response
        console.log(response?.data);
        setShowScreen(3); // Switch to another screen after successful submission
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  return (
    <>
      <AdminSidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <UserNavbar />
        <div className="lg:pt-10 lg:px-10 px-6 ">
          <div className='flex justify-between'>
            <h3 className=' font-bold text-[31px]'>Create Course</h3>
          </div>
        </div>
        <div className="relative overflow-x-auto shadow-md rounded-lg border-2 border-[#D9D9D9] mx-6 lg:mt-4 lg:mx-10 mt-5">
          <form 
          // onSubmit={createSchoolProgram}
          >
          <div className={showScreen === 1 ? "block " : "hidden"}>
            <div className='flex md:flex-row flex-col sm:pt-10 py-6 md:px-8 px-4'>
            <div className='basis-8/12'>
                {/* upload image */}
                <div className="w-full lg:w-12/12">
                  <div className="flex items-center justify-center w-full">
                    <label className="flex flex-col items-center justify-center w-full h-[96px] rounded-[5px] cursor-pointer bg-[#EDF0F2]">
                      <div className="flex flex-col items-center justify-center pt-6 ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#949db1" d="M11 13Zm-8 8q-.825 0-1.413-.588T1 19V7q0-.825.588-1.413T3 5h3.15L8 3h6v2H8.875L7.05 7H3v12h16v-9h2v9q0 .825-.588 1.413T19 21H3ZM19 7V5h-2V3h2V1h2v2h2v2h-2v2h-2Zm-8 10.5q1.875 0 3.188-1.313T15.5 13q0-1.875-1.313-3.188T11 8.5q-1.875 0-3.188 1.313T6.5 13q0 1.875 1.313 3.188T11 17.5Zm0-2q-1.05 0-1.775-.725T8.5 13q0-1.05.725-1.775T11 10.5q1.05 0 1.775.725T13.5 13q0 1.05-.725 1.775T11 15.5Z" /></svg>
                        <p className="mb-2 text-sm text-[#6C757D] font-medium ">{coverPhoto === "No selected file" ? "No selected image" : <EllipsisText text={coverPhotoName} length={"15"} tail={`...`} />}</p>
                      </div>
                      <input id="dropzone-file" name='coverPhoto' onChange={coverPhotohandler} type="file" className={"hidden mb-2 text-sm text-[#6C757D] font-medium"} />
                    </label>
                  </div>
                </div>
                <div className='mt-[30px]'>
                  <h3 className='text-[#1DB459] text-[15px] font-semibold'>Basic Information</h3>
                  <div className="w-full lg:w-12/12  pt-4">
                    <div className="relative w-full mb-6">
                      <label
                        className="block text-[#303840] text-sm font-medium md:pl-1 mb-2"
                        htmlFor="grid-password"
                      >
                        Course Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        onChange={handleChange}
                        className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                        placeholder=""

                      />
                    </div>
                  </div>
                </div>





                <div className="w-full lg:w-12/12 md:mb-[60px] mb-[120px]">
                  <div className='mb-[10px] text-[#303840] font-medium'>Course overview</div>
                  <ReactQuill theme="snow"
                    modules={module}
                    value={courseOverview}
                    onChange={setCourseOverview}
                    placeholder={'Content of the Editor'}
                    className='h-40' />
                  
                </div>

                <div className='mt-[120px]'>
                   <div className='mb-[10px] text-[#303840]  font-medium'>Key Information</div>
                  <div className='  border border-[#CBD6E2] rounded-[10px] px-3'>
                    <div className='flex md:flex-row flex-col gap-5'>
                  <div className="w-full  mb-6 md:pt-4" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Key 1
                        </label>
                        <input
                          type="text"
                          name="key_1" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      <div className="w-full mb-6 md:pt-4" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Value 1
                        </label>
                        <input
                          type="text"
                          name="value_1" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      </div>
                      <div className='flex md:flex-row flex-col gap-5'>
                  <div className="w-full  mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Key 2
                        </label>
                        <input
                          type="text"
                          name="key_2" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      <div className="w-full mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Value 2
                        </label>
                        <input
                          type="text"
                          name="value_2" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      </div>
                      <div className='flex md:flex-row flex-col gap-5'>
                  <div className="w-full  mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Key 3
                        </label>
                        <input
                          type="text"
                          name="key_3" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      <div className="w-full mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Value 3
                        </label>
                        <input
                          type="text"
                          name="value_3" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      </div>
                      <div className='flex md:flex-row flex-col gap-5'>
                  <div className="w-full  mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Key 4
                        </label>
                        <input
                          type="text"
                          name="key_4" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      <div className="w-full mb-6 " >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Value 4
                        </label>
                        <input
                          type="text"
                          name="value_4" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      </div>
                      <div className='flex md:flex-row flex-col gap-5'>
                  <div className="w-full  mb-6 " >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Key 5
                        </label>
                        <input
                          type="text"
                          name="key_5" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      <div className="w-full mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Value 5
                        </label>
                        <input
                          type="text"
                          name="value_5" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      </div>
                      <div className='flex md:flex-row flex-col gap-5'>
                  <div className="w-full  mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Key 6
                        </label>
                        <input
                          type="text"
                          name="key_6" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      <div className="w-full mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Value 6
                        </label>
                        <input
                          type="text"
                          name="value_6" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      </div>
                  </div>
                  </div>












              </div>
              <div className='basis-4/12 md:ml-[66px]'>
                <div className='mt-10 md:mt-0'>
                  <h3 className='mb-3 text-[#303840] font-medium'>Registration URL</h3>
                  <div className=' flex-col border border-[#CBD6E2] rounded-[10px] px-3'>
                    <div>
                      <div className="w-full lg:w-12/12  md:pt-4">
                        <div className="relative w-full mb-6">
                          <label
                            className="block text-[#303840] text-sm font-medium md:pl-1 mb-2 mt-1"
                            htmlFor="grid-password"
                          >
                            URL
                          </label>
                          <input
                            type="text"
                            name="video_url" onChange={handleChange}
                            className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                            placeholder=""

                          />
                          <span className='text-[12px] font-medium text-[#6E768C]'>Enter a valid URL.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='mt-10'>
                  <h3 className='text-[#303840] my-3 font-medium'>Options</h3>
                  <div className=' flex-col border border-[#CBD6E2] rounded-[10px] px-3'>
                    <div>
                      <div className="w-full lg:w-12/12  md:pt-4">
                        <div className="relative w-full mb-6">
                          <label
                            className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                            htmlFor="grid-password"
                          >
                            Category
                          </label>
                          <select
                            id="category"
                            name="category" onChange={handleChange}
                            className=" border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg  block w-full p-2.5 "
                          >
                            <option selected></option>
                            <option value="vocational">Vocational</option>
                            {/* <option value="high school">High School</option> */}
                            <option value="degree program">Degree Program</option>
                            <option value="master's program">Master's Program</option>
                          </select>
                          <span className='text-[12px] font-medium text-[#6E768C]'>Select a category.</span>
                        </div>
                      </div>
                      <div className="w-full lg:w-12/12 mb-6">
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Product Cost
                        </label>
                        <div className="flex">
                          <span className="inline-flex items-center px-3 text-sm text-gray-900 border border-r-0 border-gray-300 rounded-l-md ">
                            €
                          </span>
                          <input type="number" name="product_cost" onChange={handleChange} id="website-admin" className="rounded-none rounded-r-lg  border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5" placeholder="" />
                        </div>
                        <span className='text-[12px] font-medium text-[#6E768C]'>The recommended price is between €10,700 and €12,400</span>
                      </div>

                      <div className="w-full lg:w-12/12 mb-6">
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Tags
                        </label>
                        <input
                          type="text"
                          name="tags" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                        <span className='text-[12px] font-medium text-[#6E768C]'>Input one or more tags.</span>
                      </div>

                      <div className="w-full lg:w-12/12 mb-6">
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Campus
                        </label>

                        <select
                          id="category"
                          name="school" onChange={handleChange}
                          className=" border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg  block w-full p-2.5 "
                        >
                          <option selected></option>

                          {schoolList?.map(
                            (datas: any, index: any) => (
                              <option value={datas?.school_name}>{datas?.school_name}</option>
                            )
                          )
                          }

                        </select>

                      </div>

                      <div className="w-full lg:w-12/12 mb-6">
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Location
                        </label>
                        <input
                          type="text"
                          name="ects" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>

                      <div className="w-full lg:w-12/12 mb-6">
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Duration
                        </label>
                        <input
                          type="text"
                          name="duration" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

           
            </div>

            <div className="w-full lg:w-4/12 md:px-8 px-6 pb-6">
              <div className=' items-center'>
                <button 
                onClick={handleSubmit} className="font-normal text-white bg-[#1DB459] px-6 py-2.5 rounded-[3px]">Create Course</button>
              </div>
            </div>
            </div>



            <div className={showScreen === 2 ? "block " : "hidden"}>
            <div className='flex md:flex-row flex-col sm:pt-10 py-6 md:px-8 px-4'>
            <div className='basis-8/12'>
            <h3 className='text-[#1DB459] mb-[30px] text-[15px] font-semibold'>Career Paths for Graduates of the Program</h3>
              
            {courses.map((course, index) => (
            <div key={index} className="course-block mb-8">
              {/* Course Title */}
              <div className="w-full lg:w-12/12 ">
                <div className="relative w-full mb-6">
                  <label
                    className="block text-[#303840] text-sm font-medium md:pl-1 mb-2"
                    htmlFor={`title-${index}`}
                  >
                    Course Title
                  </label>
                  <input
                    type="text"
                    name={`title-${index}`}
                    value={course.title}
                    onChange={(e) => handleChange2(index, 'title', e.target.value)}
                    className="border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                    placeholder="Enter course title"
                  />
                </div>
              </div>

              {/* Course Overview */}
              <div className="w-full lg:w-12/12 mb-[100px]">
                <div className="mb-[5px] text-[#303840] font-medium">Course Overview</div>
                <ReactQuill
                  theme="snow"
                  modules={module}
                  value={course.careerDescription}
                  onChange={(value) => handleChange2(index, 'courseOverview', value)}
                  placeholder="Content of the editor"
                  className="h-40"
                />
              </div>

              {/* Remove button if there are more than one course block */}
              {courses.length > 1 && (
                <button
                  type="button"
                  onClick={() => handleRemoveCourse(index)}
                  className="text-red-500"
                >
                  Remove Course
                </button>
              )}
            </div>
          ))}

          {/* Button to add new course block */}
          <button type="button" onClick={handleAddCourse} className="mb-4 bg-blue-500 text-white p-2 rounded">
            Add Another Course
          </button>


              </div>
           
            </div>

            <div className="w-full lg:w-4/12 md:px-8 px-6 pb-6">
              <div className=' items-center'>
                <button 
                onClick={handleSubmit2} className="font-normal text-white bg-[#1DB459] px-6 py-2.5 rounded-[3px]">Create Course</button>
              </div>
            </div>

            </div>
            {/* <div className="w-full lg:w-4/12 md:px-8 px-6 pb-6">
              <div className=' items-center'>
                <button type='submit' className="font-normal text-white bg-[#1DB459] px-6 py-2.5 rounded-[3px]">Create Course</button>
              </div>
            </div> */}
          </form>

        </div>
      </div>


      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />

    </>
  )
}

export default AddCourse