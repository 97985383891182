import { AxiosGlobal } from "../shared/axios";
import{ AxiosPromise} from "axios";
import configs from "../../configs";
import { store } from "../../Components/store/store";



export class AdinLiveApis extends AxiosGlobal{  
    
    getAllUsers(pageNo:any,query:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-all-users?page=${pageNo}&search=${query?.search}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    getAllAgents(pageNo:any,query:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-all-agents?page=${pageNo}&search=${query?.search}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    getWinterStudent(pageNo:any,query:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-winter-student?page=${pageNo}&search=${query?.search}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    getMasterStudent(pageNo:any,query:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-master-student?page=${pageNo}&search=${query?.search}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    getPaymentDetails(pageNo:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-payments-details?page=${pageNo}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    getUserDetailsById(id:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-user-details/${id}`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    getAllSubAdmins(pageNo:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-all-subAdmin?page=${pageNo}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    getAllGroups(pageNo:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-groups?page=${pageNo}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    updateGroupLink(id: any, data: any): AxiosPromise<Array<any>> {
        return this.axios.put(`${configs.context}/admin/update-group/${id}`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    getPaidStudents(pageNo:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-paid-students?page=${pageNo}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    getUserCount(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/count-users`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    

    getUsers(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-users`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    getWinters(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-winters`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }


    getAgents(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-agents`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }


    getMasters(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-masters`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    deleteUser(id:any): AxiosPromise<Array<any>> {
        return this.axios.delete(`${configs.context}/admin/delete-user/${id}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    getCookProgram(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-cook-student`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    getBlogs(pageNo:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-blogs?page=${pageNo}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    } 

    createGroup(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/admin/create-group`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    createPartnerSchool(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/admin/create-partner-school`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }  

    createCourseCareerPath(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/career-paths`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    createCourseLearningOutcome(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/learning-outcome`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    createCourseEligibility(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/eligibility`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    createLanguageCourse(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/language`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    createPartnerCourse(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/admin/create-partner-course`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", 
                "Authorization": `Bearer ${store.getState().data.login.value.token}`, 
                "Access-Control-Allow-Origin": "*" 
            },
        });
    }  

    updatePartnerSchool(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/admin/update-partner-school`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    updateProgram(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/admin/update-program`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }


    updatePartnerCourse(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/admin/update-partner-course`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    deletePartnerCourse(data: any): AxiosPromise<Array<any>> {
        return this.axios.delete(`${configs.context}/admin/delete-partner-course/${data}`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 



    deletePartnerSchool(data: any): AxiosPromise<Array<any>> {
        return this.axios.delete(`${configs.context}/admin/delete-partner-school/${data}`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    getSinglePartnerSchool(id:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-single-partner-school/${id}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    } 

    getPartnerSchool(pageNo:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-partner-school?page=${pageNo}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    } 

    getSinglePartnerCourse2(id: any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-single-partner-course2/${id}`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    getSingleCourse(id: any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-single-partner-course/${id}`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    getPartnerCourse(pageNo:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-partner-course?page=${pageNo}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    createFaq(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/admin/create-faq`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 
    
    updateFaq(id:any, data: any): AxiosPromise<Array<any>> {
        return this.axios.put(`${configs.context}/admin/update-faq/${id}`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    getAllFaq(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-all-faq`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*"},
          });
    }

    deleteFaq(data: any): AxiosPromise<Array<any>> {
        return this.axios.delete(`${configs.context}/admin/delete-faq/${data}`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 


    createBlog(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/admin/create-blog`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    deleteBlog(data: any): AxiosPromise<Array<any>> {
        return this.axios.delete(`${configs.context}/admin/delete-blog/${data}`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    getPaymentData(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/get-registrant-data`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    updateBlog(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/admin/update-blog`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    updateAgentStatus(data: any): AxiosPromise<Array<any>> {
        return this.axios.put(`${configs.context}/admin/update-agent-status/${data}`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    getSingleBlog(id:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-single-blog/${id}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    } 

   

}